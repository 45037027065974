export const seeds = {
    "1": ['Arch', 'Vapor', 'Sunset', '#1f366f'],
    "2": ['Aframe', 'Blue', 'Sunset', '#deffee'],
    "3": ['Horizontal', 'Black', 'Sunset', '#deffee'],
    "5": ['Arch', 'Cream', 'Maze', '#1f366f'],
    "6": ['Horizontal', 'Cream', 'Maze', '#1f366f'],
    "7": ['Arch', 'Cream', 'Glacier', '#fedef2'],
    "4": ['Horizontal', 'Cream', 'Glacier', '#deffee'],
    "10": ['Aframe', 'Black', 'Sunset', '#1f366f'],
    "9": ['Arch', 'Vapor', 'Strips & Checks', '#fedef2'],
    "11": ['Aframe', 'Black', 'Strips & Checks', '#1f366f'],
    "12": ['Horizontal', 'Blue', 'Glacier', '#deffee'],
    "13": ['Aframe', 'Blue', 'Strips & Checks', '#d51cd8'],
    "8": ['Arch', 'Cream', 'Sunset', '#d51cd8'],
    "14": ['Aframe', 'Vapor', 'Void', '#fedef2'],
    "15": ['Horizontal', 'Red', 'Glacier', '#deffee'],
    "16": ['Arch', 'Red', 'Void', '#1f366f'],
    "17": ['Aframe', 'Cream', 'Glacier', '#deffee'],
    "18": ['Arch', 'Vapor', 'Sunset', '#d51cd8'],
    "19": ['Aframe', 'Black', 'Strips & Checks', '#fedef2'],
    "20": ['Horizontal', 'Blue', 'Void', '#deffee']
}










