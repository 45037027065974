import styles from "./Overlay.module.css";

export function Overlay() {
  return (
    <div className={styles.container}>
      <header>
        <h1>
          <strong>Nounitecture</strong><sup>testnet</sup>
        </h1>

      </header>
      <section>
        A modular, public domain(cc0) virtual home ready for the metaverse based on&nbsp;
        <a
            href="https://nouns.wtf"
            rel="noreferrer"
            className={styles.selected}
          >
             NounsDAO
          </a>

      </section>
      <nav>
          <a
            href="https://mirror.xyz/0xA642Cbd12afb09d724303dFEAAbE6eE5bD9E44F2/bauxTzf0UPOdjMO11TrKHY42Lvb7i65ZyfbCPdLmbyE"
            rel="noreferrer"
            className={styles.selected}
          >
            / learn more
          </a>
        </nav>
    </div>
  );
}

