/*
  Auto-generated by Spline
*/
import React, { useEffect, useMemo, useState } from 'react'
import useSpline from '@splinetool/r3f-spline'
import { PerspectiveCamera } from '@react-three/drei'
import { useControls, button } from 'leva'
import saveAs from 'file-saver'
// import {
//   getUrlParams,
//   generateSeededRandomness,
//   fetchTokens
// } from './helpers'
import { seeds } from './seeds'

export default function Scene({ ...props }) {
  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get("id")
  const seed = queryParams.get("seed")
  const address = queryParams.get("address")

  const { nodes, materials } = useSpline('https://prod.spline.design/H-QVJSIrxHrDH0m8/scene.splinecode')

  let FormOptions = [ "Horizontal", "Aframe", "Arch"]
  const [{ Form }, setForm] = useControls(() => ({
    Form: { options: FormOptions },
  })); 

  let GlassesOptions = [ "Vapor", "Blue", "Black", "Cream", "Red"]
  const [{ Glasses }, setGlasses] = useControls(() => ({
    Glasses: { options: GlassesOptions },
  })); 

  let WallOptions = [ "Sunset", "Maze", "Glacier", "Void", "Strips & Checks"]
  const [{ Wall }, setWall] = useControls(() => ({
    Wall: { options: WallOptions },
  })); 

  let BackgroundOptions = [ "#fedef2", "#1f366f", "#deffee", "#d51cd8"] 
  const [{ Background }, setBackground] = useControls(() => ({
    Background: { options: BackgroundOptions}, 
  })); 

  if (seed && seed <=20){
    let currConfig = seeds[seed]
    setForm({ Form: currConfig[0] });
    setGlasses({ Glasses: currConfig[1] });
    setWall({ Wall: currConfig[2] });
    setBackground({ Background: currConfig[3] });
  } 

  // const [myObj, setMyObj] = useState({});
  // const [seed, setSeed] = useState(0);

  const temp = {
    'generate random Home': button(()=>{     
      let newForm = FormOptions[Math.floor(Math.random() * FormOptions.length)]   
      let newGlasses = GlassesOptions[Math.floor(Math.random() * GlassesOptions.length)]   
      let newWall = WallOptions[Math.floor(Math.random() * WallOptions.length)]   
      let newBackground = BackgroundOptions[Math.floor(Math.random() * BackgroundOptions.length)] 
      
      setForm({ Form: newForm });
      setGlasses({ Glasses: newGlasses });
      setWall({ Wall: newWall });
      setBackground({ Background: newBackground });

      let currOptions = `['${newForm}', '${newGlasses}', '${newWall}', '${newBackground}']`
      // if (currOptions in myObj) {
      //   console.log('seen before')
      // } else {
      //   console.log('found seed', seed + 1)
      //   setSeed(seed + 1)
      //   let newObj = {currOptions: seed}

      //   setMyObj(myObj => ({
      //     ...myObj,
      //     ...newObj
      //   }))
        
      //   console.log('new obj', myObj)

      // }
    }),
    'download Image': button(() => {
      alert('Image downloading when Mainnet launches...')

      return
      var image = document
        .getElementsByTagName('canvas')[0]
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')

      saveAs(image, 'nounitecture.png')
    }),
    'download 3D Model': button(()=>{ alert('3D downloading when Mainnet launches...')}),
    'download Code': button(()=>{ alert('Code downloading when Mainnet launches...')})
  }

  useControls('exports', temp, { collapsed: false })
  
  return (
    <>
      <color attach="background" args={[Background]} />
      <group {...props} dispose={null}>
        <mesh
          name="Nouny"
          geometry={nodes.Nouny.geometry}
          material={materials['Nouny Material']}
          castShadow
          receiveShadow
          position={[-3, 3.98, 83.08]}
          rotation={[0, 0.23, 0]}
          scale={0.52}
        />
        <group name="WALLS" position={[-0.05, 33.04, 12.56]}>
          {Wall === 'Maze' && (
            <mesh
              name="Maze"
              geometry={nodes.Maze.geometry}
              material={materials['WALL - MAZE']}
              castShadow
              receiveShadow
              position={[52.05, -0.04, -0.06]}
              rotation={[0, -Math.PI / 2, 0]}
            />
          )}

          {Wall === 'Void' && (
            <group name="Void" position={[-0.07, 0.66, 0]}>
              <mesh
                name="void array 1"
                geometry={nodes['void array 1'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[-39.8, -25.8, -2.5]}
                scale={[0.96, 1, 1]}
              />
              <mesh
                name="void array 2"
                geometry={nodes['void array 2'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[-39.8, 25.8, -2.5]}
                scale={[0.96, 1, 1]}
              />
              <mesh
                name="void array 3"
                geometry={nodes['void array 3'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[-48.06, 25.8, -2.5]}
                rotation={[0, 0, -Math.PI / 2]}
              />
              <mesh
                name="void array 4"
                geometry={nodes['void array 4'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[48.06, 25.8, -2.5]}
                rotation={[0, 0, -Math.PI / 2]}
              />
            </group>
          )}

          {Wall === 'Sunset' && (
            <mesh
              name="Sunset"
              geometry={nodes.Sunset.geometry}
              material={materials['WALL - SUNSET']}
              castShadow
              receiveShadow
              position={[52.05, -0.04, -0.06]}
              rotation={[0, -Math.PI / 2, 0]}
            />
          )}

          {Wall === 'Glacier' && (
            <mesh
              name="Glacier"
              geometry={nodes.Glacier.geometry}
              material={materials['WALL - GLACIER']}
              castShadow
              receiveShadow
              position={[52.05, -0.04, -0.06]}
              rotation={[0, -Math.PI / 2, 0]}
            />
          )}

          {Wall === 'Strips & Checks' && (
            <mesh
              name="Stripes-Checks"
              geometry={nodes['Stripes-Checks'].geometry}
              material={materials['WALL - STRIPES-CHECKS']}
              castShadow
              receiveShadow
              position={[-52.05, 0, 0.06]}
              rotation={[-Math.PI, Math.PI / 2, 0]}
              scale={[1, 0.55, 1.57]}
            />
          )}

        </group>
        <group name="GLASSES" position={[0.04, 34.5, 18.95]}>
          {Glasses === 'Vapor' && (
            <group name="Vapor" position={[-57.54, 0, -5.11]}>
              <mesh
                name="louvers 2"
                geometry={nodes['louvers 2'].geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[115.69, -0.43, 82.17]}
              />
              <mesh
                name="louvers1"
                geometry={nodes.louvers1.geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[0, -0.43, 82.17]}
              />
              <mesh
                name="FRAME-BRIDGE"
                geometry={nodes['FRAME-BRIDGE'].geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[57.5, 5.2, 82.17]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <mesh
                name="FRAME-R"
                geometry={nodes['FRAME-R'].geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[115.09, 0, 82.17]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME R LEG"
                geometry={nodes['FRAME R LEG'].geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[151.59, 0, -5.5]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME L LEG"
                geometry={nodes['FRAME L LEG'].geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[-36.5, 0, -5.5]}
              />
              <mesh
                name="FRAME -L"
                geometry={nodes['FRAME -L'].geometry}
                material={materials['FRAME - RAINBOW']}
                castShadow
                receiveShadow
                position={[0, 0, 82.17]}
              />
            </group>
          )}

          {Glasses === 'Blue' && (
            <group name="Blue" position={[-57.54, 0, -5.11]}>
              <group name="GLASS" position={[72.29, -0.5, 83.39]}>
                <mesh
                  name="LENS - R"
                  geometry={nodes['LENS - R'].geometry}
                  material={materials['GLASS - DARK']}
                  castShadow
                  receiveShadow
                  position={[57.79, 0, -1]}
                />
                <mesh
                  name="LENS - L"
                  geometry={nodes['LENS - L'].geometry}
                  material={materials['GLASS - DARK']}
                  castShadow
                  receiveShadow
                  position={[-57.79, 0, -1]}
                />
              </group>
              <mesh
                name="FRAME-BRIDGE1"
                geometry={nodes['FRAME-BRIDGE1'].geometry}
                material={materials['FRAME - BLUE']}
                castShadow
                receiveShadow
                position={[57.5, 5.2, 82.17]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <mesh
                name="FRAME-R1"
                geometry={nodes['FRAME-R1'].geometry}
                material={materials['FRAME - BLUE']}
                castShadow
                receiveShadow
                position={[115.09, 0, 82.17]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME R LEG1"
                geometry={nodes['FRAME R LEG1'].geometry}
                material={materials['FRAME - BLUE']}
                castShadow
                receiveShadow
                position={[151.59, 0, -5.5]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME L LEG1"
                geometry={nodes['FRAME L LEG1'].geometry}
                material={materials['FRAME - BLUE']}
                castShadow
                receiveShadow
                position={[-36.5, 0, -5.5]}
              />
              <mesh
                name="FRAME -L1"
                geometry={nodes['FRAME -L1'].geometry}
                material={materials['FRAME - BLUE']}
                castShadow
                receiveShadow
                position={[0, 0, 82.17]}
              />
            </group>
          )}

          {Glasses === 'Black' && (
            <group name="Black" position={[-57.54, 0, -5.11]}>
              <group name="GLASS1" position={[72.29, -0.5, 83.39]}>
                <mesh
                  name="LENS - R1"
                  geometry={nodes['LENS - R1'].geometry}
                  material={materials['GLASS - BLACK']}
                  castShadow
                  receiveShadow
                  position={[42.79, 0, 1.27]}
                />
                <mesh
                  name="LENS - L1"
                  geometry={nodes['LENS - L1'].geometry}
                  material={materials['GLASS - BLACK']}
                  castShadow
                  receiveShadow
                  position={[-72.79, 0, 1.27]}
                />
              </group>
              <mesh
                name="FRAME-BRIDGE2"
                geometry={nodes['FRAME-BRIDGE2'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[57.5, 5.2, 82.17]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <mesh
                name="FRAME-R2"
                geometry={nodes['FRAME-R2'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[115.09, 0, 82.17]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME R LEG2"
                geometry={nodes['FRAME R LEG2'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[151.59, 0, -5.5]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME L LEG2"
                geometry={nodes['FRAME L LEG2'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[-36.5, 0, -5.5]}
              />
              <mesh
                name="FRAME -L2"
                geometry={nodes['FRAME -L2'].geometry}
                material={materials['FRAME - BLACK']}
                castShadow
                receiveShadow
                position={[0, 0, 82.17]}
              />
            </group>
          )}

          {Glasses === 'Cream' && (
            <group name="Cream" position={[-57.54, 0, -5.11]}>
              <group name="GLASS2" position={[72.29, -0.5, 83.39]}>
                <mesh
                  name="LENS - R2"
                  geometry={nodes['LENS - R2'].geometry}
                  material={materials['GLASS  - DICHROIC']}
                  castShadow
                  receiveShadow
                  position={[57.79, 0, -1]}
                />
                <mesh
                  name="LENS - L2"
                  geometry={nodes['LENS - L2'].geometry}
                  material={materials['GLASS  - DICHROIC']}
                  castShadow
                  receiveShadow
                  position={[-57.79, 0, -1]}
                />
              </group>
              <mesh
                name="FRAME-BRIDGE3"
                geometry={nodes['FRAME-BRIDGE3'].geometry}
                material={materials['FRAME - METAL']}
                castShadow
                receiveShadow
                position={[57.5, 5.2, 82.17]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <mesh
                name="FRAME-R3"
                geometry={nodes['FRAME-R3'].geometry}
                material={materials['FRAME - METAL']}
                castShadow
                receiveShadow
                position={[115.09, 0, 82.17]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME R LEG3"
                geometry={nodes['FRAME R LEG3'].geometry}
                material={materials['FRAME - METAL']}
                castShadow
                receiveShadow
                position={[151.59, 0, -5.5]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME L LEG3"
                geometry={nodes['FRAME L LEG3'].geometry}
                material={materials['FRAME - METAL']}
                castShadow
                receiveShadow
                position={[-36.5, 0, -5.5]}
              />
              <mesh
                name="FRAME -L3"
                geometry={nodes['FRAME -L3'].geometry}
                material={materials['FRAME - METAL']}
                castShadow
                receiveShadow
                position={[0, 0, 82.17]}
              />
            </group>
          )}

          {Glasses === 'Red' && (
            <group name="Red" position={[-57.54, 0, -5.11]}>
              <group name="GLASS3" position={[72.29, -0.5, 83.39]}>
                <mesh
                  name="LENS - R3"
                  geometry={nodes['LENS - R3'].geometry}
                  material={materials['GLASS - DARK']}
                  castShadow
                  receiveShadow
                  position={[57.79, 0, -1]}
                />
                <mesh
                  name="LENS -L"
                  geometry={nodes['LENS -L'].geometry}
                  material={materials['GLASS - DARK']}
                  castShadow
                  receiveShadow
                  position={[-57.79, 0, -1]}
                />
              </group>
              <mesh
                name="FRAME-BRIDGE4"
                geometry={nodes['FRAME-BRIDGE4'].geometry}
                material={materials['FRAME - RED']}
                castShadow
                receiveShadow
                position={[57.5, 5.2, 82.17]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <mesh
                name="FRAME-R4"
                geometry={nodes['FRAME-R4'].geometry}
                material={materials['FRAME - RED']}
                castShadow
                receiveShadow
                position={[115.09, 0, 82.17]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME R LEG4"
                geometry={nodes['FRAME R LEG4'].geometry}
                material={materials['FRAME - RED']}
                castShadow
                receiveShadow
                position={[151.59, 0, -5.5]}
                scale={[-1, 1, 1]}
              />
              <mesh
                name="FRAME L LEG4"
                geometry={nodes['FRAME L LEG4'].geometry}
                material={materials['FRAME - RED']}
                castShadow
                receiveShadow
                position={[-36.5, 0, -5.5]}
              />
              <mesh
                name="FRAME -L4"
                geometry={nodes['FRAME -L4'].geometry}
                material={materials['FRAME - RED']}
                castShadow
                receiveShadow
                position={[0, 0, 82.17]}
              />
            </group>
          )}

        </group>
        <group name="FORMS" position={[-1.85, 55.25, 13.8]}>
          {Form === 'Arch' && (
            <group name="Arch" position={[-4.15, -22.61, 0.2]}>
              <mesh
                name="ARCH"
                geometry={nodes.ARCH.geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[5.5, -28.14, -0.2]}
              />
              <mesh
                name="ARCH - FLOOR"
                geometry={nodes['ARCH - FLOOR'].geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[5.71, -32.64, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                scale={[0.79, 0.88, 1]}
              />
            </group>
          )}

          {Form === 'Aframe' && (
            <group name="Aframe" position={[-4.15, -22.61, 0.2]}>
              <mesh
                name="A FRAME - L"
                geometry={nodes['A FRAME - L'].geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[-83.87, 22.69, 0]}
                rotation={[Math.PI / 2, Math.PI / 6, -Math.PI / 2]}
                scale={[0.79, 0.88, 1]}
              />
              <mesh
                name="A FRAME - R"
                geometry={nodes['A FRAME - R'].geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[90.23, 19.91, 0]}
                rotation={[-Math.PI / 2, Math.PI / 6, -Math.PI / 2]}
                scale={[0.79, 0.88, 1]}
              />
              <mesh
                name="A FRAME - FLOOR"
                geometry={nodes['A FRAME - FLOOR'].geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[4.39, -32.64, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                scale={[0.79, 0.88, 1]}
              />
            </group>
          )}

          {Form === 'Horizontal' && (
            <group name="Horizontal" position={[-4.15, -22.61, 0.2]}>
              <mesh
                name="MIES - TOP SLAB"
                geometry={nodes['MIES - TOP SLAB'].geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[0, 29.64, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                scale={[0.79, 0.88, 1]}
              />
              <mesh
                name="MIES - BOTTOM SLAB"
                geometry={nodes['MIES - BOTTOM SLAB'].geometry}
                material={materials.SLAB}
                castShadow
                receiveShadow
                position={[0, -32.64, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                scale={[0.79, 0.88, 1]}
              />
            </group>
          )}

        </group>
        <directionalLight
          name="Directional Light"
          castShadow
          intensity={0.7}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={1}
          shadow-camera-far={2500}
          shadow-camera-left={-1250}
          shadow-camera-right={1250}
          shadow-camera-top={1250}
          shadow-camera-bottom={-1250}
          position={[119.48, 222.2, 335]}
        />
        <PerspectiveCamera
          name="Personal Camera"
          makeDefault={true}
          far={100000}
          near={5}
          fov={45}
          up={[0, 1, 0]}
          position={[226, 154, 390]}
          rotation={[0.02, 0.89, -0.17]}
        />
        <hemisphereLight name="Default Ambient Light" intensity={0.8} color="#f5f5ef" position={[0, 1, 0]} />
      </group>
    </>
  )
}
